import { isObjEmpty } from "utils"

const validateForm = formData => {
  const requiredFields = formData?.requiredFields
  let isError = false
  let errorFields = {}
  Object.keys(requiredFields?.fields).forEach(key => {
    if (
      typeof formData[key] === "object" &&
      formData[key] !== null &&
      isObjEmpty(formData[key])
    ) {
      isError = true
      errorFields[key] = "Please enter/select a value"
    }
    if (!formData[key] && typeof formData[key] !== "object") {
      isError = true
      errorFields[key] = "Please enter/select a value"
    }
  })
  return { isError: isError, errorFields: errorFields }
}

export { validateForm }
