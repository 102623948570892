import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"

import Dropzone from "react-dropzone"

// Scss
import "./AddBankDetails.scss"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { bankLoanTypes, institutionTypes } from "constants/bankdetails"
import { useDispatch, useSelector } from "react-redux"
import {
  addBankDetailsAction,
  clearAddBankDetailsFormData,
  clearAddBankDetailsFormError,
  updateAddBankDetailsFormData,
} from "store/addBankDetailsFormSlice"
import { fileUpload } from "api/api"
import { baseUrl } from "config/axios"
import { showToast } from "common/toastr"
import { validateForm } from "./utils"

const AddBankDetails = () => {
  const dispatch = useDispatch()

  const addBankDetailsFormData = useSelector(
    state => state.addBankDetailsFormData
  )

  useEffect(() => {
    if (
      addBankDetailsFormData.status === "success" &&
      !addBankDetailsFormData.isLoading
    ) {
      showToast({
        message: "Bank Details successfully added",
        toastType: "success",
        title: "Add: Bank Details",
      })
      dispatch(clearAddBankDetailsFormData())
    } else if (
      addBankDetailsFormData.status === "failure" &&
      !addBankDetailsFormData.isLoading
    ) {
      showToast({
        message: "Something went wrong. Please try again.",
        toastType: "error",
        title: "Add: Bank Details",
      })
    }
  }, [addBankDetailsFormData.isLoading, addBankDetailsFormData.status])

  useEffect(() => {
    return () => {
      dispatch(clearAddBankDetailsFormData())
    }
  }, [])

  const requiredFields = addBankDetailsFormData?.data?.requiredFields?.fields
  const formData = addBankDetailsFormData?.data

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    if (files.length) {
      fileUpload(files[0])
        .then(res => {
          dispatch(
            updateAddBankDetailsFormData({
              image: files,
              imageUrl: `${baseUrl}/api${res?.data?.fileUrl}`,
            })
          )
        })
        .catch(err => {
          console.log("data>err", err)
        })
    }
  }

  const handleDeleteImage = () => {
    dispatch(
      updateAddBankDetailsFormData({
        image: [],
      })
    )
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const prepareAddBankDetailsData = data => {
    const preparedData = {
      bankImage: data.imageUrl,
      institutionType: data.bankType.value,
      loanCategory: data.loanTypes.value,
      bankName: data.bankNbfcName,
      intrestRate: data.interestRate,
      minLoanAmount: data.minimumLoanAmount,
      maxLoanAmount: data.maximumLoanamount,
      minTenure: data.minimumTenure,
      maxTenure: data.maximumTenure,
      processingFees: data.feesAndCharges,
    }
    return preparedData
  }

  const handleSubmit = () => {
    dispatch(clearAddBankDetailsFormError())
    const { isError, errorFields } = validateForm(formData)
    if (!isError) {
      dispatch(addBankDetailsAction(prepareAddBankDetailsData(formData)))
    } else {
      dispatch(
        updateAddBankDetailsFormData({
          requiredFields: {
            fields: {
              ...requiredFields,
              ...errorFields,
            },
            isError: isError,
          },
        })
      )
    }
  }

  const handleChange = (key, data, type) => {
    dispatch(
      updateAddBankDetailsFormData({
        [key]: type === "input" ? data?.target?.value : data,
      })
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Bank Details | Admin - Shri Sai Finance</title>
        </MetaTags>
        <Container fluid className="add-bank-details-container">
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Bank Details" breadcrumbItem="Add Bank Details" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>

                  <Form
                    className="form-horizontal"
                    // onSubmit={e => {
                    //   e.preventDefault()
                    //   handleSubmit()
                    //   return false
                    // }}
                  >
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="bankNbfcName">Bank/NBFC Name</Label>
                          <Input
                            id="bankNbfcName"
                            name="bankNbfcName"
                            type="text"
                            className="form-control"
                            required
                            autoFocus
                            onChange={value =>
                              handleChange("bankNbfcName", value, "input")
                            }
                            value={formData.bankNbfcName || ""}
                            invalid={!!requiredFields.bankNbfcName}
                          />
                          {requiredFields.bankNbfcName ? (
                            <FormFeedback type="invalid">
                              {requiredFields.bankNbfcName}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 add-bank-details-select">
                          <Label className="control-label">Loan Category</Label>
                          <div
                            className={
                              requiredFields.loanTypes
                                ? "invalid-select-border-indicator"
                                : ""
                            }
                          >
                            <Select
                              classNamePrefix="select2-selection form-control"
                              placeholder="Select Loan Category"
                              name="loanTypes"
                              title="loanTypes"
                              options={bankLoanTypes}
                              isMulti={false}
                              onChange={value =>
                                handleChange("loanTypes", value, "select")
                              }
                              value={formData.loanTypes}
                            />
                          </div>
                          {requiredFields.loanTypes ? (
                            <div className="add-bank-details-invalid-content">
                              {requiredFields.loanTypes}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="minimumLoanAmount">
                            Minimum Loan Amount
                          </Label>
                          <Input
                            id="minimumLoanAmount"
                            name="minimumLoanAmount"
                            type="text"
                            className="form-control"
                            onChange={value =>
                              handleChange("minimumLoanAmount", value, "input")
                            }
                            value={formData.minimumLoanAmount || ""}
                            invalid={!!requiredFields.minimumLoanAmount}
                          />
                          {requiredFields.minimumLoanAmount ? (
                            <FormFeedback type="invalid">
                              {requiredFields.minimumLoanAmount}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="minimumTenure">Minimum Tenure</Label>
                          <Input
                            id="minimumTenure"
                            name="minimumTenure"
                            type="text"
                            className="form-control"
                            onChange={value =>
                              handleChange("minimumTenure", value, "input")
                            }
                            value={formData.minimumTenure || ""}
                            invalid={!!requiredFields.minimumTenure}
                          />
                          {requiredFields.minimumTenure ? (
                            <FormFeedback type="invalid">
                              {requiredFields.minimumTenure}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="feesAndCharges">Fees & Charges</Label>
                          <Input
                            id="feesAndCharges"
                            name="feesAndCharges"
                            type="text"
                            className="form-control"
                            onChange={value =>
                              handleChange("feesAndCharges", value, "input")
                            }
                            value={formData.feesAndCharges || ""}
                            invalid={!!requiredFields.feesAndCharges}
                          />
                          {requiredFields.feesAndCharges ? (
                            <FormFeedback type="invalid">
                              {requiredFields.feesAndCharges}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3 add-bank-details-select">
                          <Label className="control-label">Bank/NBFC</Label>
                          <div
                            className={
                              requiredFields.bankType
                                ? "invalid-select-border-indicator"
                                : ""
                            }
                          >
                            <Select
                              classNamePrefix="select2-selection form-control"
                              placeholder="Select Bank/NBFC"
                              name="bankType"
                              title="bankType"
                              options={institutionTypes}
                              isMulti={false}
                              onChange={value =>
                                handleChange("bankType", value, "select")
                              }
                              value={formData.bankType || {}}
                            />
                          </div>
                          {requiredFields.bankType ? (
                            <div className="add-bank-details-invalid-content">
                              {requiredFields.bankType}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="interestRate">Interest Rate</Label>
                          <Input
                            id="interestRate"
                            name="interestRate"
                            type="text"
                            className="form-control"
                            onChange={value =>
                              handleChange("interestRate", value, "input")
                            }
                            value={formData.interestRate || ""}
                            invalid={!!requiredFields.interestRate}
                          />
                          {requiredFields.interestRate ? (
                            <FormFeedback type="invalid">
                              {requiredFields.interestRate}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="maximumLoanamount">
                            Maximum Loan Amount
                          </Label>
                          <Input
                            id="maximumLoanamount"
                            name="maximumLoanamount"
                            type="text"
                            className="form-control"
                            onChange={value =>
                              handleChange("maximumLoanamount", value, "input")
                            }
                            value={formData.maximumLoanamount || ""}
                            invalid={!!requiredFields.maximumLoanamount}
                          />
                          {requiredFields.maximumLoanamount ? (
                            <FormFeedback type="invalid">
                              {requiredFields.maximumLoanamount}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="maximumTenure">Maximum Tenure</Label>
                          <Input
                            id="maximumTenure"
                            name="maximumTenure"
                            type="text"
                            className="form-control"
                            onChange={value =>
                              handleChange("maximumTenure", value, "input")
                            }
                            value={formData.maximumTenure || ""}
                            invalid={!!requiredFields.maximumTenure}
                          />
                          {requiredFields.maximumTenure ? (
                            <FormFeedback type="invalid">
                              {requiredFields.maximumTenure}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <CardTitle className="mb-3">Bank/NBFC Image</CardTitle>
                    {!formData.image.length ? (
                      <Dropzone
                        multiple={false}
                        disabled={!!formData.image.length}
                        accept="image/*"
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <>
                            <div
                              className={`dropzone ${
                                requiredFields.imageUrl
                                  ? "add-bank-details-dropzone-error"
                                  : ""
                              }`}
                            >
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                            {requiredFields.imageUrl ? (
                              <div
                                type="invalid"
                                className="add-bank-details-invalid-feedback"
                              >
                                {requiredFields.imageUrl}
                              </div>
                            ) : null}
                          </>
                        )}
                      </Dropzone>
                    ) : null}
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {formData.image.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col className="col-10">
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col className="col-1">
                                  <Button
                                    type="button"
                                    className="btn btn-danger  w-xs"
                                    onClick={handleDeleteImage}
                                  >
                                    <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                    Delete
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        color="primary"
                        className="btn "
                        disabled={addBankDetailsFormData.isLoading}
                        onClick={e => {
                          e.preventDefault()
                          handleSubmit()
                        }}
                      >
                        {addBankDetailsFormData.isLoading && (
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                        )}
                        Save Changes
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddBankDetails
