import { configureStore } from "@reduxjs/toolkit"
import layoutReducer from "./layoutSlice"
import userReducer from "./userSlice"
import addBankDetailsFormReducer from "./addBankDetailsFormSlice"
import editBankDetailsFormReducer from "./editBankDetailsFormSlice"
import bankDetailsByIdReducer from "./bankDetailsByIdSlice"
import bankListReducer from "./bankListSlice"
import addAwardsFormReducer from "./addAwardsFormSlice"
import awardListReducer from "./awardListSlice"

export const reducer = {
  user: userReducer,
  layout: layoutReducer,
  addBankDetailsFormData: addBankDetailsFormReducer,
  editBankDetailsFormData: editBankDetailsFormReducer,
  bankDetailsByIdData: bankDetailsByIdReducer,
  bankListData: bankListReducer,
  addAwardsFormData: addAwardsFormReducer,
  awardListData: awardListReducer,
}

export default configureStore({
  reducer,
})
