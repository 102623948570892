import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { editBankDetails } from "api/api"
import { bankLoanTypes, institutionTypes } from "constants/bankdetails"

const initialState = {
  data: {
    id: "",
    bankNbfcName: "",
    minimumLoanAmount: "",
    minimumTenure: "",
    feesAndCharges: "",
    interestRate: "",
    maximumLoanamount: "",
    maximumTenure: "",
    loanTypes: {},
    bankType: {},
    image: [],
    imageUrl: "",
    requiredFields: {
      isError: false,
      fields: {
        bankNbfcName: "",
        minimumLoanAmount: "",
        minimumTenure: "",
        feesAndCharges: "",
        interestRate: "",
        maximumLoanamount: "",
        maximumTenure: "",
        loanTypes: "",
        bankType: "",
        image: "",
        imageUrl: "",
      },
    },
  },
  isLoading: false,
  status: "idle",
}

export const editBankDetailsAction = createAsyncThunk(
  "editBankDetailsForm/edit",
  async data => {
    const response = await editBankDetails(data)
    return {
      ...response,
    }
  }
)

const editBankDetailsFormSlice = createSlice({
  name: "editBankDetailsForm",
  initialState,
  reducers: {
    updateEditBankDetailsFormData: (state, action) => {
      const newData = { ...state.data, ...action.payload }
      state.data = newData
      return state
    },
    clearEditBankDetailsFormData: () => {
      return initialState
    },
    clearEditBankDetailsFormError: state => {
      state.data.requiredFields = initialState.data.requiredFields
      return state
    },
  },
  extraReducers: {
    [editBankDetailsAction.pending]: state => {
      state.isLoading = true
      return state
    },
    [editBankDetailsAction.fulfilled]: state => {
      state.isLoading = false
      state.data = initialState.data
      state.status = "success"
      return state
    },
    [editBankDetailsAction.rejected]: state => {
      state.isLoading = false
      state.status = "failure"
      return state
    },
    "bankDetails/getBankDetailsById/fulfilled": (state, { payload }) => {
      const {
        bankNbfcName,
        bankType,
        feesAndCharges,
        imageUrl,
        interestRate,
        loanTypes,
        maximumLoanamount,
        maximumTenure,
        minimumLoanAmount,
        minimumTenure,
        _id,
      } = payload
      state.isLoading = false
      const newData = {
        id: _id,
        bankNbfcName: bankNbfcName,
        minimumLoanAmount: minimumLoanAmount,
        minimumTenure: minimumTenure,
        feesAndCharges: feesAndCharges,
        interestRate: interestRate,
        maximumLoanamount: maximumLoanamount,
        maximumTenure: maximumTenure,
        loanTypes: bankLoanTypes.find(types => types.value === loanTypes) || {},
        bankType:
          institutionTypes.find(types => types.value === bankType) || {},
        image: [],
        imageUrl: imageUrl,
        requiredFields: initialState.data.requiredFields,
      }
      state.data = newData
    },
  },
})

export const {
  updateEditBankDetailsFormData,
  clearEditBankDetailsFormData,
  clearEditBankDetailsFormError,
} = editBankDetailsFormSlice.actions
export default editBankDetailsFormSlice.reducer
