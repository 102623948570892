import React, { useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { useHistory } from "react-router-dom"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
// import profile from "assets/images/profile-img.png"
import profile from "assets/images/bank-vector.png"
// import logo from "assets/images/logo.svg"
import logo from "assets/images/shri-sai-associates-with-text-logo.png"
import { userAuthentication } from "api/api"
import { addUserData } from "store/userSlice"

const Login = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isLoading, setisLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: values => {
      setisLoading(true)
      setErrorMessage("")
      userAuthentication(values)
        .then(res => {
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("userData", JSON.stringify(res.userData))
          dispatch(addUserData(res))
          setisLoading(false)
          history.push("/add-bank-details")
        })
        .catch(err => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong!!"
          )
          setisLoading(false)
          localStorage.removeItem("token")
        })
    },
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Admin - Shri Sai Finance</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>
                          Sign in to continue to <br /> Admin - Shri Sai
                          Finance.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {errorMessage ? (
                        <Alert color="danger">{errorMessage}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <Button
                          className="btn btn-primary btn-block"
                          color="primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                          )}
                          Log In
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Shri Sai Finance. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Rukvaak
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
