import { createSlice } from "@reduxjs/toolkit"

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../constants/layout"

const initialState = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.DARK,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
}

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeLayout(state, action) {
      state.layoutType = action.payload
      return state
    },
    changePreloader(state, action) {
      state.isPreloader = action.payload
      return state
    },
    changeLayoutWidth(state, action) {
      state.layoutWidth = action.payload
      return state
    },
    changeSidebarTheme(state, action) {
      state.leftSideBarTheme = action.payload
      return state
    },
    changeSidebarThemeImage(state, action) {
      state.leftSideBarThemeImage = action.payload
      return state
    },
    changeSidebarType(state, action) {
      state.leftSideBarType = action.payload.sidebarType
      return state
    },
    changeTopbarTheme(state, action) {
      state.topbarTheme = action.payload
      return state
    },
    showRightSidebarAction(state, action) {
      state.showRightSidebar = action.payload
      return state
    },
    showSidebar(state, action) {
      state.showSidebar = action.payload
      return state
    },
    toggleLeftmenu(state, action) {
      state.leftMenu = action.payload
      return state
    },
  },
})

export const {
  changeLayout,
  changePreloader,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebarAction,
  showSidebar,
  toggleLeftmenu,
} = layoutSlice.actions

export default layoutSlice.reducer
