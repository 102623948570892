import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

import Dropzone from "react-dropzone"

// Scss
import "./AddAwards.scss"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  addAwardsAction,
  clearAddAwardsFormData,
  clearAddAwardsFormError,
  updateAddAwardsFormData,
} from "store/addAwardsFormSlice"
import { addAwards, fileUpload } from "api/api"
import { baseUrl } from "config/axios"
import { showToast } from "common/toastr"
import { validateForm } from "./utils"

const AddAwards = () => {
  const dispatch = useDispatch()

  const addAwardsFormData = useSelector(state => state.addAwardsFormData)

  useEffect(() => {
    if (
      addAwardsFormData.status === "success" &&
      !addAwardsFormData.isLoading
    ) {
      showToast({
        message: "Award successfully added",
        toastType: "success",
        title: "Add: Award",
      })
      dispatch(clearAddAwardsFormData())
    } else if (
      addAwardsFormData.status === "failure" &&
      !addAwardsFormData.isLoading
    ) {
      showToast({
        message: "Something went wrong. Please try again.",
        toastType: "error",
        title: "Add: Award",
      })
    }
  }, [addAwardsFormData.isLoading, addAwardsFormData.status])

  useEffect(() => {
    return () => {
      dispatch(clearAddAwardsFormData())
    }
  }, [])

  const requiredFields = addAwardsFormData?.data?.requiredFields?.fields
  const formData = addAwardsFormData?.data

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    if (files.length) {
      fileUpload(files[0])
        .then(res => {
          dispatch(
            updateAddAwardsFormData({
              image: files,
              imageUrl: `${baseUrl}/api${res?.data?.fileUrl}`,
            })
          )
        })
        .catch(err => {
          console.log("data>err", err)
        })
    }
  }

  const handleDeleteImage = () => {
    dispatch(
      updateAddAwardsFormData({
        image: [],
      })
    )
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const prepareAddAwardsData = data => {
    const preparedData = {
      awardName: data.awardName,
      awardImage: data.imageUrl,
    }
    return preparedData
  }

  const handleSubmit = () => {
    dispatch(clearAddAwardsFormError())
    const { isError, errorFields } = validateForm(formData)
    if (!isError) {
      dispatch(addAwardsAction(prepareAddAwardsData(formData)))
    } else {
      dispatch(
        updateAddAwardsFormData({
          requiredFields: {
            fields: {
              ...requiredFields,
              ...errorFields,
            },
            isError: isError,
          },
        })
      )
    }
  }

  const handleChange = (key, data, type) => {
    dispatch(
      updateAddAwardsFormData({
        [key]: type === "input" ? data?.target?.value : data,
      })
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Awards | Admin - Shri Sai Finance</title>
        </MetaTags>
        <Container fluid className="add-awards-container">
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Awards" breadcrumbItem="Add Awards" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>

                  <Row>
                    <Col sm="6">
                      <div className="mb-3">
                        <Label htmlFor="awardName">Award Name</Label>
                        <Input
                          id="awardName"
                          name="awardName"
                          type="text"
                          className="form-control"
                          required
                          autoFocus
                          onChange={value =>
                            handleChange("awardName", value, "input")
                          }
                          value={formData.awardName || ""}
                          invalid={!!requiredFields.awardName}
                        />
                        {requiredFields.awardName ? (
                          <FormFeedback type="invalid">
                            {requiredFields.awardName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      handleSubmit()
                      return false
                    }}
                  >
                    <CardTitle className="mb-3">Awards Image</CardTitle>
                    {!formData.image.length ? (
                      <Dropzone
                        multiple={false}
                        disabled={!!formData.image.length}
                        accept="image/*"
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <>
                            <div
                              className={`dropzone ${
                                requiredFields.imageUrl
                                  ? "add-awards-dropzone-error"
                                  : ""
                              }`}
                            >
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                            {requiredFields.imageUrl ? (
                              <div
                                type="invalid"
                                className="add-awards-invalid-feedback"
                              >
                                {requiredFields.imageUrl}
                              </div>
                            ) : null}
                          </>
                        )}
                      </Dropzone>
                    ) : null}
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {formData.image.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col className="col-10">
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col className="col-1">
                                  <Button
                                    type="button"
                                    className="btn btn-danger  w-xs"
                                    onClick={handleDeleteImage}
                                  >
                                    <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                    Delete
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                    <div className="d-flex flex-wrap gap-2 mt-3">
                      <Button
                        type="submit"
                        color="primary"
                        className="btn "
                        disabled={addAwardsFormData.isLoading}
                      >
                        {addAwardsFormData.isLoading && (
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                        )}
                        Add Award
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddAwards
