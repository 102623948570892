import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useHistory, withRouter } from "react-router-dom"

// import { logoutUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

const Logout = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogoutNavigation = async () => {
    await localStorage.clear()
    history.replace("/login")
  }

  useEffect(() => {
    handleLogoutNavigation()
  }, [dispatch])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
