import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { clearBankListData, getBankListAction } from "store/bankListSlice"
import { deleteAwardApi } from "api/api"

import { getAwardListAction } from "store/awardListSlice"

const AwardsList = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [photoIndex, setphotoIndex] = useState(0)
  const [isGallery, setisGallery] = useState(false)

  useEffect(() => {
    dispatch(getAwardListAction())
    return () => {
      dispatch(clearBankListData())
    }
  }, [])

  const handleDeleteAward = awardId => {
    deleteAwardApi(awardId)
      .then(res => {
        dispatch(getAwardListAction())
      })
      .catch(err => {
        console.log("err>", err)
      })
  }

  const awardListData = useSelector(state => state.awardListData.data)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Awards List | Admin - Shri Sai Finance</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Awards" breadcrumbItem="Awards List" />

          {isGallery ? (
            <Lightbox
              mainSrc={awardListData[photoIndex]?.awardImage}
              nextSrc={
                awardListData[(photoIndex + 1) % awardListData.length]
                  ?.awardImage
              }
              prevSrc={
                awardListData[
                  (photoIndex + awardListData.length - 1) % awardListData.length
                ]?.awardImage
              }
              enableZoom={true}
              onCloseRequest={() => {
                setisGallery(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + awardListData.length - 1) % awardListData.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % awardListData.length)
              }}
              imageCaption={awardListData[photoIndex]?.awardName}
            />
          ) : null}

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Awards List</CardTitle>
                  <CardSubtitle className="mb-3">
                    All the Awards can be viewed here.
                  </CardSubtitle>
                  <div className="popup-gallery d-flex flex-wrap">
                    {awardListData.map((awardList, index) => (
                      <div
                        className="img-fluid float-left m-3"
                        key={`awards-image-${index}`}
                      >
                        <img
                          src={awardList?.awardImage}
                          onClick={() => {
                            setisGallery(true)
                            setphotoIndex(index)
                          }}
                          alt="awards"
                          width="200"
                          height="200"
                        />
                        <h6>{awardList?.awardName}</h6>
                        <Button
                          type="button"
                          className="btn btn-danger  w-xs mt-3"
                          onClick={() => handleDeleteAward(awardList?._id)}
                        >
                          <i className="mdi mdi-trash-can d-block font-size-16"></i>
                          Delete
                        </Button>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AwardsList
