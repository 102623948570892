import axios from "axios"

// const baseUrl = "http://localhost:5004"
const baseUrl = ""

let api = {}
let apiWithToken = {}
let apiMultiPartFormData = {}

const createGlobalAxios = token => {
  // localStorage.setItem("token", token)
  api = axios.create({
    baseURL: `${baseUrl}/api`,
    headers: {
      "Content-Type": "application/json",
    },
  })

  apiWithToken = axios.create({
    baseURL: `${baseUrl}/api`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })

  apiMultiPartFormData = axios.create({
    baseURL: `${baseUrl}/api`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  })
}

export { baseUrl, api, apiWithToken, apiMultiPartFormData, createGlobalAxios }
