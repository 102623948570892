import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getAwardList } from "api/api"

const initialState = {
  data: [],
  isLoading: false,
  status: "idle",
}

export const getAwardListAction = createAsyncThunk(
  "awardList/get",
  async data => {
    const response = await getAwardList(data)
    return response.data
  }
)

const awardListSlice = createSlice({
  name: "awardList",
  initialState,
  reducers: {
    clearAwardListData: () => {
      return initialState
    },
  },
  extraReducers: {
    [getAwardListAction.pending]: state => {
      state.isLoading = true
      return state
    },
    [getAwardListAction.fulfilled]: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.status = "success"
      return state
    },
    [getAwardListAction.rejected]: state => {
      state.isLoading = false
      state.status = "failure"
      return state
    },
  },
})

export const { clearAwardListData } = awardListSlice.actions
export default awardListSlice.reducer
