import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addBankDetails } from "api/api"

const initialState = {
  data: {
    bankNbfcName: "",
    minimumLoanAmount: "",
    minimumTenure: "",
    feesAndCharges: "",
    interestRate: "",
    maximumLoanamount: "",
    maximumTenure: "",
    loanTypes: {},
    bankType: {},
    image: [],
    imageUrl: "",
    requiredFields: {
      isError: false,
      fields: {
        bankNbfcName: "",
        minimumLoanAmount: "",
        minimumTenure: "",
        feesAndCharges: "",
        interestRate: "",
        maximumLoanamount: "",
        maximumTenure: "",
        loanTypes: "",
        bankType: "",
        image: "",
        imageUrl: "",
      },
    },
  },
  isLoading: false,
  status: "idle",
}

export const addBankDetailsAction = createAsyncThunk(
  "addBankDetailsForm/add",
  async data => {
    const response = await addBankDetails(data)
    return {
      ...response,
    }
  }
)

const addBankDetailsFormSlice = createSlice({
  name: "addBankDetailsForm",
  initialState,
  reducers: {
    updateAddBankDetailsFormData: (state, action) => {
      const newData = { ...state.data, ...action.payload }
      state.data = newData
      return state
    },
    clearAddBankDetailsFormData: () => {
      return initialState
    },
    clearAddBankDetailsFormError: state => {
      state.data.requiredFields = initialState.data.requiredFields
      return state
    },
  },
  extraReducers: {
    [addBankDetailsAction.pending]: state => {
      state.isLoading = true
      return state
    },
    [addBankDetailsAction.fulfilled]: state => {
      state.isLoading = false
      state.data = initialState.data
      state.status = "success"
      return state
    },
    [addBankDetailsAction.rejected]: state => {
      state.isLoading = false
      state.status = "failure"
      return state
    },
  },
})

export const {
  updateAddBankDetailsFormData,
  clearAddBankDetailsFormData,
  clearAddBankDetailsFormError,
} = addBankDetailsFormSlice.actions
export default addBankDetailsFormSlice.reducer
