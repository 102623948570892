import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getBankList } from "api/api"

const initialState = {
  data: [],
  isLoading: false,
  status: "idle",
}

export const getBankListAction = createAsyncThunk(
  "bankList/get",
  async data => {
    const response = await getBankList(data)
    return response.data
  }
)

const bankListSlice = createSlice({
  name: "bankList",
  initialState,
  reducers: {
    clearBankListData: () => {
      return initialState
    },
  },
  extraReducers: {
    [getBankListAction.pending]: state => {
      state.isLoading = true
      return state
    },
    [getBankListAction.fulfilled]: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.status = "success"
      return state
    },
    [getBankListAction.rejected]: state => {
      state.isLoading = false
      state.status = "failure"
      return state
    },
  },
})

export const { clearBankListData } = bankListSlice.actions
export default bankListSlice.reducer
