import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addAwards } from "api/api"

const initialState = {
  data: {
    awardName: "",
    image: [],
    imageUrl: "",
    requiredFields: {
      isError: false,
      fields: {
        awardName: "",
        image: "",
        imageUrl: "",
      },
    },
  },
  isLoading: false,
  status: "idle",
}

export const addAwardsAction = createAsyncThunk(
  "addAwardsForm/add",
  async data => {
    const response = await addAwards(data)
    return {
      ...response,
    }
  }
)

const addAwardsFormSlice = createSlice({
  name: "addAwardsForm",
  initialState,
  reducers: {
    updateAddAwardsFormData: (state, action) => {
      const newData = { ...state.data, ...action.payload }
      state.data = newData
      return state
    },
    clearAddAwardsFormData: () => {
      return initialState
    },
    clearAddAwardsFormError: state => {
      state.data.requiredFields = initialState.data.requiredFields
      return state
    },
  },
  extraReducers: {
    [addAwardsAction.pending]: state => {
      state.isLoading = true
      return state
    },
    [addAwardsAction.fulfilled]: state => {
      state.isLoading = false
      state.data = initialState.data
      state.status = "success"
      return state
    },
    [addAwardsAction.rejected]: state => {
      state.isLoading = false
      state.status = "failure"
      return state
    },
  },
})

export const {
  updateAddAwardsFormData,
  clearAddAwardsFormData,
  clearAddAwardsFormError,
} = addAwardsFormSlice.actions
export default addAwardsFormSlice.reducer
