import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  userData: "",
  token: "",
  isLoggedIn: false,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserData(state, action) {
      state.userData = action.payload.userData
      state.token = action.payload.token
      state.isLoggedIn = true
      return state
    },
  },
})

export const { addUserData } = userSlice.actions
export default userSlice.reducer
