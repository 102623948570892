import { api, apiMultiPartFormData, apiWithToken } from "../config/axios"

const userAuthenticationUrl = "/users/validate"
const addBankDetailsUrl = "/intrestrates"
const editBankDetailsUrl = "/intrestrates"
const fileUploadUrl = "/fileupload"
const getBankListUrl = "/intrestrates"
const getBankDetailsByIdUrl = "/intrestrates"
const deleteBankDetailsUrl = "/intrestrates"
const addAwardsUrl = "/awards"
const getAwardsListUrl = "/awards"
const deleteAwardUrl = "/awards"

// Authentication
export function userAuthentication(userData) {
  return api.post(userAuthenticationUrl, userData)
}

// Add Bank Details
export function addBankDetails(data) {
  return apiWithToken.post(addBankDetailsUrl, data)
}

// Edit Bank Details
export function editBankDetails(data) {
  return apiWithToken.put(editBankDetailsUrl, data)
}

// Get Bank Details By Id
export function getBankDetailsById(id) {
  return apiWithToken.get(`${getBankDetailsByIdUrl}?id=${id}`)
}

// Get Bank List
export function getBankList(loanCategory) {
  return apiWithToken.get(`${getBankListUrl}?loanCategory=${loanCategory}`)
}

// Delete Bank Details
export function deleteBankDetailsApi(id) {
  return apiWithToken.delete(deleteBankDetailsUrl, {
    headers: {},
    data: { _id: id },
  })
}
// File Upload
export function fileUpload(file) {
  const formData = new FormData()
  formData.append("myFile", file)
  return apiMultiPartFormData.post(fileUploadUrl, formData)
}

// Add Awards
export function addAwards(data) {
  return apiWithToken.post(addAwardsUrl, data)
}

// Get Award List
export function getAwardList() {
  return apiWithToken.get(getAwardsListUrl)
}

// Delete Award
export function deleteAwardApi(id) {
  return apiWithToken.delete(deleteAwardUrl, {
    headers: {},
    data: { _id: id },
  })
}
