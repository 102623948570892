import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getBankDetailsById } from "api/api"

const initialState = {
  data: {},
  isLoading: false,
  status: "idle",
}

export const getBankDetailsByIdAction = createAsyncThunk(
  "bankDetails/getBankDetailsById",
  async data => {
    const response = await getBankDetailsById(data)
    return response.data
  }
)

const bankDetailsByIdSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {},
  extraReducers: {
    [getBankDetailsByIdAction.pending]: state => {
      state.isLoading = true
      state.data = initialState.data
      return state
    },
    [getBankDetailsByIdAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.data = payload
      state.status = "success"
    },
    [getBankDetailsByIdAction.rejected]: state => {
      state.isLoading = false
      state.data = initialState.data
      state.status = "failure"
      return state
    },
  },
})

export const {} = bankDetailsByIdSlice.actions
export default bankDetailsByIdSlice.reducer
