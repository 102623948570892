import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  bankListColumnHeaders,
  bankLoanTypes,
  institutionTypes,
} from "constants/bankdetails"
import { businessLoanBankListMock } from "__mocks__/businessLoanBankList"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { clearBankListData, getBankListAction } from "store/bankListSlice"
import { deleteBankDetailsApi } from "api/api"

const BankList = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    return () => {
      dispatch(clearBankListData())
    }
  }, [])

  const [loanCategory, setLoanCategory] = useState({})

  const bankListData = useSelector(state => state.bankListData.data)

  const handleLoanCategoryChange = data => {
    setLoanCategory(data)
  }

  const displayBankList = e => {
    e.preventDefault()
    if (loanCategory?.value) {
      dispatch(getBankListAction(loanCategory?.value))
    }
  }

  const handleViewBankDetails = id => {
    history.push({ pathname: "/edit-bank-details", search: id })
  }

  const deleteBankDetails = id => {
    deleteBankDetailsApi(id)
      .then(res => {
        if (loanCategory?.value) {
          dispatch(getBankListAction(loanCategory?.value))
        }
      })
      .catch(err => {
        console.log("err>", err)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Bank List | Admin - Shri Sai Finance</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Bank Details" breadcrumbItem="Bank List" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Select the Loan Category and click on display list to get
                    the bank list
                  </CardSubtitle>

                  <Row>
                    <Col xs="6">
                      <div className="mb-3">
                        <Label className="control-label">Loan Category</Label>
                        <Select
                          classNamePrefix="select2-selection form-control"
                          placeholder="Select Loan Category"
                          name="loanTypes"
                          title="loanTypes"
                          options={bankLoanTypes}
                          isMulti={false}
                          onChange={handleLoanCategoryChange}
                          value={loanCategory}
                        />
                      </div>
                      <div className="mb-3">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn "
                          disabled={!loanCategory?.value}
                          onClick={displayBankList}
                        >
                          Display Bank List
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="bank-list"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            {bankListColumnHeaders.map(bankListHeader => {
                              return (
                                <Th
                                  key={bankListHeader.id}
                                  data-priority={bankListHeader.dataPriority}
                                >
                                  {bankListHeader.label}
                                </Th>
                              )
                            })}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {bankListData.map(businessLoanBankList => {
                            return (
                              <Tr key={businessLoanBankList.id}>
                                {Object.keys(businessLoanBankList.data).map(
                                  listKey => {
                                    switch (listKey) {
                                      case "image":
                                        return (
                                          <Td>
                                            <img
                                              className="rounded ms-2"
                                              alt="No Image"
                                              width="150"
                                              src={
                                                businessLoanBankList.data[
                                                  listKey
                                                ]
                                              }
                                            />
                                          </Td>
                                        )
                                      case "banksnbfc":
                                        return (
                                          <Th>
                                            <span className="fw-medium">
                                              {
                                                businessLoanBankList.data[
                                                  listKey
                                                ]
                                              }
                                            </span>
                                          </Th>
                                        )
                                      case "institutionType":
                                        return (
                                          <Td>
                                            {
                                              institutionTypes.find(
                                                bankType =>
                                                  bankType.value ===
                                                  businessLoanBankList.data[
                                                    listKey
                                                  ]
                                              ).label
                                            }
                                          </Td>
                                        )
                                      default:
                                        return (
                                          <Td>
                                            {businessLoanBankList.data[listKey]}
                                          </Td>
                                        )
                                    }
                                  }
                                )}
                                <Td>
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn btn-sm"
                                    onClick={e => {
                                      e.preventDefault()
                                      handleViewBankDetails(
                                        businessLoanBankList.id
                                      )
                                    }}
                                  >
                                    View Details
                                  </Button>
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn btn-sm mt-3"
                                    onClick={e => {
                                      e.preventDefault()
                                      deleteBankDetails(businessLoanBankList.id)
                                    }}
                                  >
                                    Delete Row
                                  </Button>
                                </Td>
                              </Tr>
                            )
                          })}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BankList
