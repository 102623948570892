const bankLoanTypes = [
  {
    id: "1",
    value: "businessLoan",
    label: "Business Loan",
  },
  {
    id: "2",
    value: "homeLoan",
    label: "Home Loan",
  },
  {
    id: "3",
    value: "personalLoan",
    label: "Personal Loan",
  },
]

const institutionTypes = [
  {
    id: "1",
    value: "bank",
    label: "Bank",
  },
  {
    id: "2",
    value: "nbfc",
    label: "NBFC",
  },
  {
    id: "2",
    value: "hfc",
    label: "HFC",
  },
]

const loanTypes = [
  {
    id: "1",
    value: "businessLoan",
    label: "Business Loan",
  },
  {
    id: "2",
    value: "homeLoan",
    label: "Home Loan",
  },
  {
    id: "2",
    value: "personalLoan",
    label: "Personal Loan",
  },
]

const bankListColumnHeaders = [
  {
    id: "1",
    dataPriority: "1",
    value: "image",
    label: "Image",
  },
  {
    id: "2",
    dataPriority: "2",
    value: "banksnbfc",
    label: "Banks/NBFC",
  },
  {
    id: "3",
    dataPriority: "3",
    value: "institutionType",
    label: "Institution Type",
  },
  {
    id: "4",
    dataPriority: "4",
    value: "minimumLoanAmount",
    label: "Minimum Loan Amount",
  },
  {
    id: "5",
    dataPriority: "5",
    value: "maximumLoanAmount",
    label: "Maximum Loan Amount",
  },
  {
    id: "6",
    dataPriority: "6",
    value: "minimumTenure",
    label: "Minimum Tenure",
  },
  {
    id: "7",
    dataPriority: "7",
    value: "maximumTenure",
    label: "Maximum Tenure",
  },
  {
    id: "8",
    dataPriority: "8",
    value: "interestRates",
    label: "Interest Rates",
  },
  {
    id: "9",
    dataPriority: "9",
    value: "feesAndCharges",
    label: "Fees & Charges",
  },
]

export { bankLoanTypes, institutionTypes, loanTypes, bankListColumnHeaders }
